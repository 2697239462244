<template>
  <chip :class="['rent-booking-status', getStatusClass()]">
    {{ status.name }}
  </chip>
</template>

<script>
import Chip from "@/components/GlobalComponents/Chip";
import { rentBookingStatus as STATUS } from "@/enums";

export default {
  components: {
    Chip
  },
  mixins: [],
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      STATUS
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusClass() {
      switch (Number(this.status.id)) {
        case STATUS.OTR_IN_PROGRESS:
          return "bg-warn fg-white";
        case STATUS.OTR_COMPLETED:
          return "bg-main fg-white";
        case STATUS.RENT_CANCELLED:
          return "bg-grey-700 fg-white";
        case STATUS.RENT_COMPLETED:
          return "bg-success fg-white";

        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss">
.rent-booking-status {
}
</style>
